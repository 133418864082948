// icons via CDN
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");

// als je de complete bootstrap.scss inlaadt, moet je variabelen overschrijven voor het inladen ervan
// je vindt _variables.scss en alle andere Bootstrap Sass bestanden op onderstaand pad
//ook utilities overschrijven kan op deze plaats - vergeet niet de !default flag mee over te nemen
@import "./node_modules/bootstrap/scss/bootstrap";

//extra custom code plaats je onder het inladen, bv.:
body {
  background-color: #1F1F1F;
}

// wil je Bootstrap niet volledig inladen maar slechts de Sass partials en JS bestanden die je nodig hebt, ziehier hoe je dat kan doen https://getbootstrap.com/docs/5.0/customize/optimize/

html, body {
  max-width: 100%;
  //overflow-x: hidden;
}

p {
  font-family: 'Libre Baskerville', serif;
  letter-spacing: 1px;
  line-height: 5;
}

.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #151515;
  z-index: 4;
  opacity: 1;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.pcircle {
  width: 130px;
  height: 130px;
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 65px);
  border: 1px solid #da9d80;
  border-radius: 100px;
  border-top: 0;
  border-right: 0;
}

.herologo {
  max-width: 130px;
  filter: drop-shadow(2px 4px 6px #00000080);
  position: absolute;
  left: calc(50% - 65px);
  top: calc(50vh - 128px);
  clip-path: url(#masker);
  -webkit-clip-path: url(#masker);
  object-fit: cover;
}

.herologoemb {
  max-width: 130px;
  min-width: 100px;
  filter: drop-shadow(2px 4px 6px #00000080);
  z-index: 99;
  position: absolute;
  top: 50%;


  z-index: 99;

  min-width: 100px;
  //position: fixed;

}

.embcont {
  transform-origin: top;
}

.menubg {
  content: '';
  width: 110px;
  left: 10px;
  top: 10px;
  height: 110px;
  position: absolute;
  background-image: url(/img/circle.png);
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
transform: scale(0);
}

.menubg a {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: all;
}

.pin-spacer {
  pointer-events: none;
}

.navlogo {
  min-width: 100px;
  //position: fixed;
  top: calc(50vh - 130px);
  left: 50%;
  transform: translate(-50%);
  margin: 0px;
 
}

.hero {
  background-image:  linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url('/img/blackwater.jpg');
  background-size: cover;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh + 100px);
  overflow: hidden;
}

.hero .box {
  position: absolute;
  width: calc(100vw - 75px);
  border: 2px solid #535353;
  height: calc(100vh - 75px);
  left: 50%;
  top: calc(50% - 50px);
  transform: translate(-50%, -50%);
  z-index: 3;
  border-bottom: 0px;
  overflow: hidden;
}

.box .leftline {
  position: absolute;
  bottom: 0px;
  display: block;
  height: 2px;
  background: #535353;
  z-index: 200;
  width: 52%;
  left: 0;
  transform-origin: left;
}

.box .rightline {
  position: absolute;
  bottom: 0px;
  display: block;
  height: 2px;
  background: #535353;
  z-index: 200;
  width: 52%;
  right: 0;
  transform-origin: right;
}

.line {
	position: absolute;
	width: 50px;
	height: 2px;
	background-color: #474747;
}
.tl-line {
	left: 30px;
	top: 50px;
	transform: rotate(-45deg);
}
.bl-line {
	left: 30px;
	top: calc(100vh - 130px);
	transform: rotate(45deg);
}
.tr-line {
	right: 30px;
	top: 50px;
	transform: rotate(45deg);
}
.br-line {
	right: 30px;
	top: calc(100vh - 130px);
	transform: rotate(-45deg);
}

.about p {
  color: #8D8D8D;
  text-shadow: 4px 4px 4px #1a1a1a, 8px 8px 8px #1a1a1a;
  filter: drop-shadow(4px 4px 4px #1a1a1a);
}

.abouthead {
	background-image: url('/img/copper2.jpg');
	background-size: cover;
	position: absolute;
	width: 55vw;
	text-align: left;
	top: -160px;
	left: -75px;
	box-shadow: 5px 5px 5px #000000d9, 10px 10px 30px #0000007d;
  z-index: 4;
  -webkit-mask-image: url(/img/aboutborder.svg);
    -webkit-mask-size: 98% 98%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
}

@media only screen and (max-width: 920px) {
  .abouthead img {
  transform: translateX(-20px) scale(.95)
  }
}

@media only screen and (max-width: 768px) {
  .abouthead {
    width: 100vw;
    left: 30px;
  }

  .abouthead img {
    transform: translateX(0px) scale(1);
    max-width: 330px !important;
    left: 0;
    padding: 10px 10px 10px 10px;
    position: relative;

    }
}

.abouthead img {
	max-width: 434px;
	left: 0px;
	padding: 10px 10px 10px 40px;
	position: relative;
}
.mainsec {
  padding-top: 100px;
  position: absolute;
  top: calc(100vh + 100px);
  left: 50%;
  transform: translate(-50%);
  width: 100%;
}

.textmask {
  background: rgb(31,31,31);
  background: linear-gradient(180deg, rgba(31,31,31,0) 0%, rgba(31,31,31,1) 55%);
  width: 100%;
  position: absolute;
  height: 60vh;
  z-index: 100;
  top: 0;
}

.invisibletxt p {
  line-height: 2 !important;
}

.animatedtxt {
  z-index: 4;
}

span.email {
  font-family: Libre Baskerville,serif;
  position: relative;
  background-color: #111111;
  padding: 6px 10px 6px 14px;
  border: 1px solid #c18b71;
}

span.email a {
  color: #e29a79;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 13px;
  transition: .3s ease all;
}

span.email a:hover {
  letter-spacing: 4px;
  transform: translate(0px, -5px);
}

.underliner {
  position: absolute;
  width: 90%;
  height: 1px;
  background-image: url('/img/copper2.jpg');
  transition: .3s ease all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(0);
  z-index: 0;
}

span.email a:hover .underliner {
  transform: translate(-50%, -50%) scaleX(1);
}

.invisibletxt {
  padding-bottom: 60px;
}

.work {
  background-color: #000;
  width: 100%;
  height: 75vh;
  overflow: hidden;
  position: relative;
}
.lowerbox {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 150px);
  height: calc(100% - 183px);
  border: 2px solid #535353;
  z-index: 1;
  border-top: 0px;
}

.aboutbg {
  position: absolute;
  width: 100%;
  right: 20vw;
  height: 120%;
  top: -100px;
  background: url(/img/jd.jpg) #1f1f1f;
  background-blend-mode: screen;
  filter: grayscale(1);
  background-size: auto 120%;
  background-position: center -100px;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: -1;
}

.aboutmask {
  position: absolute;
  width: 100%;
  left: 0;
  height: 120%;
  top: -100px;
  opacity: 1;
  z-index: 0;
  background: rgb(31,31,31);
  background: linear-gradient(90deg, rgba(31,31,31,0) 0%, rgba(31,31,31,1) 80%);
}

.aboutbg:before {
  content: '';
  display: none;
}

@media only screen and (max-width: 768px) {

.aboutmask {
  background: rgb(31,31,31);
  background: linear-gradient(180deg, rgba(31,31,31,1) 5%, rgba(31,31,31,0) 20%, rgba(31,31,31,0) 80%, rgba(31,31,31,1) 95%);
}

.aboutbg {
  right: 20px;
}

.aboutbg:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0px;
  background: #1f1f1f;
  opacity: .4;
  z-index: 1;
}

}

.grit {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  background: url(/img/grit.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: .2;
  z-index: 2;
}

.gritcircle {
  background: url(/img/circlebg.png);
  background-size: 100%;
  background-position: 50%;
  opacity: .15;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%) scale(1.5);
}

@media only screen and (min-width: 1100px) {
  .gritcircle {
    width: 150%;
  }
}

.gritcirclecontainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.copperjd {
  background-image: url(/img/copperjd.png);
  background-size: cover;
  background-position: center center;
  position: absolute!important;
  bottom: -100px;
  left: 0;
  width: 250px;
  height: 250px;
  overflow: hidden;
  z-index: 4;
}

.coppersplatter {
  background-image: url(/img/coppersplatter.png);
  background-size: cover;
  background-position: center center;
  position: absolute!important;
  bottom: -100px;
  left: 0;
  width: 250px;
  height: 250px;
  overflow: hidden;
  z-index: 3;
}

@media only screen and (max-width: 768px) {

.copperjd, .coppersplatter {
    bottom: -160px;
    left: -50px;
}

}

span.bgoverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  z-index: 0;
  top: 0;
}

.headerlogobg {
  /*background-image: url('/img/copper-logo.png');*/
  background-size: 130px;
  background-repeat: no-repeat;
  background-position: center center;
}

#masker {
  transform: scale(2) translate(-50px, -110px);
  transform-origin: top left;
}

#boxmasker {

}

#smooth-wrapper {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#smooth-content {
  overflow: visible;
  width: 100%;
  /* set a height because the contents are position: absolute, thus natively there's no height */
  height: 2700px;
}

.video-wrapper {
  border: 0px solid #000;
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  filter: grayscale(1);
}

video {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}